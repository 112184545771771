import React, { useEffect, useState } from "react";
import { MailOutlined } from "@ant-design/icons";
import { Form, Formik, Field, ErrorMessage, FormikHelpers } from "formik";
import { gql, useLazyQuery } from "@apollo/client";
import { ValuesEmail, emailSchema, initialStateEmail } from "../../utils";
import { requestSuccessMessage } from "src/components/constants";
import { useCreateFormMutation, useGetFormEmailLazyQuery } from "src/graphql";
import { useGeneralContext } from "src/components/context";
import { useLocation } from "react-router";
import XcelButton from "src/components/ui/Button";

interface InputField {
  id: string;
  FormLabel: string;
  InputPlaceholder: string;
  errorMessage: string | null;
  inputType: string;
  isActive: boolean;
}

interface NewsletterProps {
  title: string;
  description: string;
  isActive: boolean;
  SubscribeForm?: InputField[];
}

const Newsletter: React.FC<NewsletterProps> = ({ title, description, isActive, SubscribeForm }) => {
  const [createForm] = useCreateFormMutation();
  const { messageApi } = useGeneralContext();
  const { pathname } = useLocation();
  const [getExistingEmails] = useGetFormEmailLazyQuery();
  const [footer] = document.getElementsByClassName("footerWrapper");
  const form = footer?.querySelector(".newsletterForm") as HTMLFormElement | null;
  const [newsLetterInput, setnewsLetterInput] = useState(false);

  const buttonConfigs = [
    {
      color: "#fff",
      width: "max-content",
      hoverFontColor: "#fff",
      backgroundColor: "#2251FF",
      borderColor: "transparent",
      hoverBorderColor: "transparent",
      focusColor: "#052DC3",
      hoverColor: "#0036FF",
      clickColor: "#0036FF",
      iconFillColor: "",
      hoverIconFillColor: "",
      iconStrokeColor: "",
      hoverIconStrokeColor: "",
    },
  ];

  useEffect(() => {
    if (form) {
      form.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onSubmitEmail = async (values: ValuesEmail, formikBag: FormikHelpers<ValuesEmail>) => {
    try {
      const { data } = await getExistingEmails();
    //   const formEmails = data?.forms.data.map((entry: any) => entry.attributes.email) || [];
      const subscribeEmails = data?.forms?.data.map((entry: any) => entry.attributes.email) || [];
      const allEmails = [...subscribeEmails];

      // Check if email already exists
      if (allEmails.includes(values.email)) {
        messageApi?.open({
          type: "warning",
          content: "This email is already subscribed. Please use a different email.",
        });
        return;
      }

      // If email is unique, proceed with form submission
      await createForm({ variables: { data: { email: values.email } } });
      setnewsLetterInput(true);
      formikBag.resetForm();
      messageApi?.open({ type: "success", content: requestSuccessMessage });
    } catch (error) {
      console.error("Error submitting form:", error);
      messageApi?.open({ type: "error", content: "There was an error submitting your form. Please try again." });
    }
  };

  return (
    <React.Fragment>
      <div className={"container"}>
        {isActive && (
          <div className={"footerNewsletter"}>
            <div className={"newsletterContent"}>
              <h4 className={"newsletterTitle"}>{title}</h4>
              <p className={"newsletterDesc"}>{description}</p>
            </div>
            <Formik
              initialValues={initialStateEmail}
              onSubmit={onSubmitEmail}
              validationSchema={emailSchema}
              validateOnBlur={false}
            >
              <Form className={"newsletterForm"} id={"form"}>
                <div className={"newsletterForm--container"}>
                  <div className={"newsletterFormWrapper"}>
                    {SubscribeForm?.map((field) =>
                      field.isActive ? (
                        <React.Fragment key={field.id}>
                          {field.inputType !== "submit" ? (
                            <div>
                              <Field
                                type={field.inputType}
                                name={"email"} // Assuming the form has only an email input
                                placeholder={field.InputPlaceholder}
                                className={"newsletterInput"}
                              />
                              {newsLetterInput ? (
                                <span className={"successMessage"}>
                                  You're all set – thanks for signing up!
                                </span>
                              ) : (
                                <ErrorMessage name={"email"} component={"div"} className={"errorMessage"} />
                              )}
                            </div>
                          ) : (
                            <XcelButton
                              buttonsCSS={buttonConfigs}
                              content={field.FormLabel}
                              type={"submit"}
                            />
                          )}
                        </React.Fragment>
                      ) : null
                    )}
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Newsletter;

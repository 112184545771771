import { Layout as BaseLayout } from "antd";
import React, { FC, Suspense, useEffect, useRef, useState, Fragment } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  useDarkHeaderThemePathQuery,
  useLightHeaderThemePageQuery,
  usePageQuery,
  useTrendingBlogPostCardQuery,
} from "../../graphql";
import { ContentProps } from "../constants";
import { useGeneralContext } from "../context";
import { PopUpGated } from "../section/popUpGated";
import { Chat } from "../ui/Chat";
import { Loader } from "../ui/Loader";
import { filterByPathname, getConditionIsDarkTheme } from "../utils";
import "./Layout.less";
import GlobalFooter from "../Global/GlobalFooter";
import GlobalHeader from "../Global/GlobalHeader";
import "./style/global.less";


const Layout: FC = () => {
  const { pathname } = useLocation();
  const [data, setData] = useState<ContentProps | null>(null);
  const { data: dataPage, loading } = usePageQuery();
  const { setRefLoading, setLoading, setTrendingBlogPost, contextHolder, Pageloading } = useGeneralContext();
  const { data: dataDarkHeaderThemePath, loading: loadingDarkHeaderThemePath } = useDarkHeaderThemePathQuery();
  const { data: dataLightHeaderThemePage, loading: loadingLightHeaderThemePage } = useLightHeaderThemePageQuery();
  const { data: dataTrendingBlogPost, loading: loadingTrendingBlogPost } = useTrendingBlogPostCardQuery();

  const conditionIsDarkTheme = getConditionIsDarkTheme(
    pathname,
    dataDarkHeaderThemePath?.darkHeaderThemePaths?.data?.map((elem) => elem?.attributes?.key),
    dataLightHeaderThemePage?.lightHeaderThemePages?.data?.map((elem) => elem?.attributes?.key)
  );
  const [isDarkTheme, setIsDarkTheme] = useState(conditionIsDarkTheme);

  useEffect(() => {
    setIsDarkTheme(conditionIsDarkTheme);
  }, [pathname, loadingDarkHeaderThemePath, loadingLightHeaderThemePage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setData(
      pathname && dataPage?.page?.data?.attributes?.content
        ? filterByPathname(pathname, dataPage?.page?.data.attributes?.content as ContentProps[])
        : null
    );
  }, [pathname, loading]);

  const refLoading = useRef(null);
  useEffect(() => {
    setLoading(loading);
    setRefLoading(refLoading);
  }, [loading]);

  useEffect(() => {
    if (!loadingTrendingBlogPost) {
      setTrendingBlogPost(
        dataTrendingBlogPost?.blogPosts?.data?.map((elem) => ({ isOpen: false, ...elem?.attributes }))
      );
    }
  }, [loadingTrendingBlogPost]);

  if (Pageloading) {
    return (
      <div className={"loading-screen"}>
        <Loader />
      </div>
    );
  }

  return (
    <Fragment>
      {!Pageloading && (
        <Suspense fallback={<Loader />}>
          <BaseLayout className={"layout"}>
            <GlobalHeader />
            {contextHolder}
            <PopUpGated />
            <Suspense fallback={<Loader />}>
              <div className={"wrapperMainContent"}>
                <Outlet context={data} />
              </div>
              {/* <Chat isDarkTheme={isDarkTheme} /> */}
              <GlobalFooter />
            </Suspense>
          </BaseLayout>
        </Suspense>
      )}
    </Fragment>
  );
};

export { Layout };

import { message, notification } from "antd";
import { MessageInstance } from "antd/lib/message/interface";
import { NotificationInstance, NotificationPlacement } from "antd/es/notification/interface";
import {
  Dispatch,
  FC,
  Fragment,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

// Context Props Type
type ContextProps = {
  openKeys: string[];
  setOpenKeys: Dispatch<SetStateAction<string[]>>;
  refLoading: MutableRefObject<null> | { current: Element };
  setRefLoading: Dispatch<SetStateAction<MutableRefObject<null> | { current: Element }>>;
  Pageloading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  trendingBlogPost: Maybe<Maybe<BlogPostCardFragment & { isOpen: boolean }>[]> ;
  setTrendingBlogPost: Dispatch<SetStateAction<Maybe<Maybe<BlogPostCardFragment & { isOpen: boolean }>[]> >>;
  openPopUp: boolean;
  setOpenPopUp: Dispatch<SetStateAction<boolean>>;
  messageApi: Maybe<MessageInstance>;
  notificationApi: Maybe<NotificationInstance>;
  contextHolder: ReactElement;
  fileURL: string;
  setFileURL: Dispatch<SetStateAction<string>>;
  openNotification: (message: string, description: string, placement?: NotificationPlacement) => void; // Added this function
};

// Default Values
export const defaultValues: ContextProps = {
  fileURL: "",
  setFileURL: () => null,
  openKeys: [],
  setOpenKeys: () => null,
  refLoading: { current: document.getElementById("root") },
  setRefLoading: () => null,
  Pageloading: false,
  setLoading: () => false,
  trendingBlogPost: [],
  setTrendingBlogPost: () => null,
  openPopUp: false,
  setOpenPopUp: () => false,
  messageApi: null,
  notificationApi: null,
  contextHolder: <Fragment />,
  openNotification: () => null, // Default function
};

// Create Context
const Context = createContext(defaultValues);

// Context Provider
const ContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openKeys, setOpenKeys] = useState(defaultValues.openKeys);
  const [refLoading, setRefLoading] = useState(defaultValues.refLoading);
  const [Pageloading, setLoading] = useState(defaultValues.Pageloading);
  const [trendingBlogPost, setTrendingBlogPost] = useState(defaultValues.trendingBlogPost);
  const [openPopUp, setOpenPopUp] = useState(defaultValues.openPopUp);
  const [messageApi, contextHolder] = message.useMessage();
  const [fileURL, setFileURL] = useState(defaultValues.fileURL);

  // Notification function
  const openNotification = (
    messageText: string, 
    description: string, 
    placement: NotificationPlacement = "topRight"
  ) => {
    notification.info({
      message: messageText,
      description: description,
      placement: placement,
    });
  };

  return (
    <Context.Provider
      value={{
        fileURL,
        setFileURL,
        openKeys,
        setOpenKeys,
        refLoading,
        setRefLoading,
        Pageloading,
        setLoading,
        trendingBlogPost,
        setTrendingBlogPost,
        openPopUp,
        setOpenPopUp,
        messageApi,
        notificationApi: notification, // Set the notification API directly
        contextHolder,
        openNotification, // Expose openNotification function
      }}
    >
      {children}
    </Context.Provider>
  );
};

// Custom hook to use the context
const useGeneralContext = (): ContextProps => useContext(Context);

export { ContextProvider, useGeneralContext };

import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { logoProps } from "./GlobalTypes";
import { SvgIcon } from "../ui/SvgIcon";

const HeaderLogo: React.FC<logoProps> = ({ logo }) => {
    const location = useLocation();

    if (!logo || !logo.lightLogo || !logo.lightLogo.data || !logo.lightLogo.data.attributes) {
        return null;
    }
    const { url } = logo.lightLogo.data.attributes;

    const handleLogoClick = (e: React.MouseEvent) => {
        if (location.pathname === "/") {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    return (
        <NavLink to={"/"} onClick={handleLogoClick} aria-label={"Home"}>
            <img src={url} alt={logo.Title} />
            {/* <SvgIcon type={"Logo"} className={"logo"} /> */}
        </NavLink>
    );
};

export default React.memo(HeaderLogo);
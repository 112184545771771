import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import "./style.less"
import upIcons from "../../icons/upward.svg"
import EventHero from 'src/components/ui/EventHero';
import { HiPlus, HiMinus } from "react-icons/hi2"
import { Loader } from 'src/components/ui/Loader';


interface Event {
    id: number
    createdAt: string
    updatedAt: string
    publishedAt: string
    EventTitle: string
    EventSubtitle: string
    CardTopicTitle: string
    CardIndustryTitle: string
    CardRoleTitle: string
    EventDay: string
    EventDate: string
    EventStartTime: string
    EventEndTime: string
    TimeZone: string | null
    EventVenue: string
    FormTitle: string
    FormTerms: string
    Slug: string
    Thumbnail: {
        data: {
            id: number
            attributes: {
                name: string
                alternativeText: string | null
                caption: string | null
                width: number
                height: number
                formats: {
                    thumbnail: {
                        name: string
                        hash: string
                        ext: string
                        mime: string
                        path: string | null
                        width: number
                        height: number
                        size: number
                        url: string
                    }
                }
                hash: string
                ext: string
                mime: string
                size: number
                url: string
                previewUrl: string | null
                provider: string
                provider_metadata: string | null
                createdAt: string
                updatedAt: string
                blurhash: string
            }
        }
    }
    CoverBanner: {
        data: {
            id: number
            attributes: {
                name: string
                alternativeText: string | null
                caption: string | null
                width: number
                height: number
                formats: {
                    thumbnail: {
                        name: string
                        hash: string
                        ext: string
                        mime: string
                        path: string | null
                        width: number
                        height: number
                        size: number
                        url: string
                    }
                }
                hash: string
                ext: string
                mime: string
                size: number
                url: string
                previewUrl: string | null
                provider: string
                provider_metadata: string | null
                createdAt: string
                updatedAt: string
                blurhash: string
            }
        }
    }
    EventCTA: {
        id: number
        Title: string
        Redirect: string
        Target: string
    }[]
    TopicList: {
        id: number
        Subtitle: string
    }[]
    CardIndustryList: {
        id: number
        Subtitle: string
    }[]
    CardRoleList: {
        id: number
        Subtitle: string
    }[]
    AgendaList: {
        id: number
        ListName: string
    }[]
    OverviewList: {
        id: number
        ListName: string
    }[]
    WebinarIsForList: {
        id: number
        ListName: string
    }[]
    IndustryList: {
        id: number
        ListName: string
    }[]
    EventAction: {
        Date: string | number | Date
        id: number
        Title: string
        Redirect: string
        Target: string
    }[]
    FormSubtitle: {
        id: number
        Subtitle: string
    }[]
    FormInput: {
        id: number
        InputType: string
        Placeholder: string
        Title: string
        Name: string
        Value: string
    }[]
    EventDetailedSection: {
        id: number
        __component: string
        Title: string
        Description: string
    }[]
    JoinRegisterButton: {
        id: number
        Title: string
    }
    FaqQuestions: {
        id: number
        Question: string
        Answer: string | null
    }[];
}
interface EventHost {
    id: number
    attributes: {
        Name: string
        Designation: string
        About: string
        createdAt: string
        updatedAt: string
        publishedAt: string
        Profile: {
            data: {
                id: number
                attributes: {
                    name: string
                    alternativeText: string | null
                    caption: string | null
                    width: number
                    height: number
                    formats: null
                    hash: string
                    ext: string
                    mime: string
                    size: number
                    url: string
                    previewUrl: string | null
                    provider: string
                    provider_metadata: string | null
                    createdAt: string
                    updatedAt: string
                    blurhash: string
                }
            }[]
        }
        event: {
            data: {
                id: number
                attributes: {
                    EventTitle: string
                    EventSubtitle: string
                    CardTopicTitle: string
                    CardIndustryTitle: string
                    CardRoleTitle: string
                    EventDay: string
                    EventDate: string
                    EventStartTime: string
                    EventEndTime: string
                    TimeZone: string
                    EventVenue: string
                    FormTitle: string
                    FormTerms: string
                    Slug: string
                    createdAt: string
                    updatedAt: string
                    publishedAt: string
                }
            }
        }
    }
}
interface EventFeature {
    id: number
    attributes: {
        Title: string
        createdAt: string
        updatedAt: string
        publishedAt: string
        EventFeatureList: { id: number; ListName: string }[]
        event: {
            data: {
                id: number
                attributes: {
                    EventTitle: string
                    EventSubtitle: string
                    CardTopicTitle: string
                    CardIndustryTitle: string
                    CardRoleTitle: string
                    EventDay: string
                    EventDate: string
                    EventStartTime: string
                    EventEndTime: string
                    TimeZone: string
                    EventVenue: string
                    FormTitle: string
                    FormTerms: string
                    Slug: string
                    createdAt: string
                    updatedAt: string
                    publishedAt: string
                }
            }
        }
    }
}


const EventDetailedPage = () => {
    const { Slug } = useParams()
    const [eventData, setEventData] = useState<Event | null>(null)
    const [eventHosts, setEventHosts] = useState<EventHost[]>([]) 
    const [eventFeatures, setEventFeatures] = useState<EventFeature[]>([])
    const [activeIndex, setActiveIndex] = useState<number | null>(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const isLocal = window.location.hostname === "localhost";
                const isDev = window.location.hostname.includes("staging.");
                const isUat = window.location.hostname.includes("uat.");

                const baseURL = isLocal
                    ? "http://localhost:8082/api/"
                    : isDev
                        ? "https://staging.xcelpros.com/api/"
                        : isUat
                            ? "https://uat.xcelpros.com/api/"
                            : "https://www.xcelpros.com/api/";

                // Fetch event data
                const eventResponse = await axios.get(`${baseURL}events-card?populate=*`)
                const allEvents = eventResponse.data.data
                if (!Array.isArray(allEvents)) {
                    console.error("API response data is not an array:", allEvents)
                    return
                }
                const filteredEvent = allEvents.find(event => event.attributes.Slug === Slug)
                setEventData(filteredEvent ? filteredEvent.attributes : null)

                // Fetch event host data
                const hostResponse = await axios.get(`${baseURL}event-hosts?populate=*`)
                const allHosts = hostResponse.data.data
                if (!Array.isArray(allHosts)) {
                    console.error("API response data is not an array:", allHosts)
                    return
                }
                // Filter event hosts based on the event slug
                const filteredHosts = allHosts.filter((host: any) => {
                    return host.attributes.events.data.some((event: any) => event.attributes.Slug === Slug)
                })


                // Update state with filtered hosts
                setEventHosts(filteredHosts)
                // console.log(filteredHosts)


                // Fetch event features data
                const featureResponse = await axios.get(`${baseURL}event-features?populate=*`)
                const allFeatures = featureResponse.data.data

                // Filter features based on the event slug

                const filteredFeatures = allFeatures.filter((feature: any) => {
                    return feature.attributes.events.data.some((feature: any) => feature.attributes.Slug === Slug)
                })

                // Update state with filtered features
                setEventFeatures(filteredFeatures)
            } catch (error) {
                console.error("Error fetching data:", error)
            }
        }

        fetchData()
        document.body.classList.add("event-detailed-page")
        return () => {
            document.body.classList.remove("event-detailed-page")
        }
    }, [Slug])

    const formatDate = (dateString: string) => {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ]
        const [day, datePart] = dateString.split(", ")
        const [year, month, dayOfMonth] = datePart.split("-")
        return `${day}, ${months[parseInt(month, 10) - 1]} ${parseInt(dayOfMonth, 10)}, ${year}`
    }

    const handleEventAction = async (action: any) => {
        try {
            await axios.post(action.Redirect, { eventId: action.EventId })
            // console.log(`${action.ActionType} performed`)
        } catch (error) {
            console.error(`Error performing ${action.ActionType}:`, error)
        }
    }

    const formatTime = (timeString: string) => {
        const convertTo24HourFormat = (time: string) => {
          const [hour, minute, second] = time.split(":");
          return `${hour}:${minute}`;
        };
        return convertTo24HourFormat(timeString);
      };
      
    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index)
    }


    if (!eventData) {
        return <Loader />
    }

    // console.log(eventHosts)
    return (
        <Fragment>
            <EventHero
                eventData={eventData || {}}
                formatDate={formatDate}
                formatTime={formatTime}
                handleEventAction={handleEventAction}
            />
            <div className={"agenda-section"}>
                <div className={"container"}>
                    <h3 className={"agenda-title"}>
                        {(eventData &&
                            eventData.EventDetailedSection &&
                            eventData.EventDetailedSection[0] &&
                            eventData.EventDetailedSection[0].Title) ||
                            ""}
                    </h3>
                    <div className={"description-container"}>
                        <p>
                            {(eventData &&
                                eventData.EventDetailedSection &&
                                eventData.EventDetailedSection[0] &&
                                eventData.EventDetailedSection[0].Description) ||
                                ""}
                        </p>
                    </div>
                    <div className={"agenda-list-container"}>
                        <div className={"agenda-column"}>
                            <ul className={"agenda-list"}>
                                {eventData &&
                                    eventData.AgendaList &&
                                    eventData.AgendaList.slice(0, Math.ceil(eventData.AgendaList.length / 2)).map(
                                        (agendaItem, index) => <li key={index}>{agendaItem.ListName || ""}</li>
                                    )}
                            </ul>
                        </div>
                        <div className={"agenda-column agenda-right-column"}>
                            <ul className={"agenda-list"}>
                                {eventData &&
                                    eventData.AgendaList &&
                                    eventData.AgendaList.slice(Math.ceil(eventData.AgendaList.length / 2)).map((agendaItem, index) => (
                                        <li key={index}>{agendaItem.ListName || ""}</li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"overview-section"}>
                <div className={"container"}>
                    <div className={"overview-container"}>
                        <h3 className={"overview-section-title"}>
                            {(eventData &&
                                eventData.EventDetailedSection &&
                                eventData.EventDetailedSection[1] &&
                                eventData.EventDetailedSection[1].Title) ||
                                ""}
                        </h3>
                        <div className={"description-container"}>
                            <p>
                                {(eventData &&
                                    eventData.EventDetailedSection &&
                                    eventData.EventDetailedSection[1] &&
                                    eventData.EventDetailedSection[1].Description) ||
                                    ""}
                            </p>
                        </div>
                        <ul className={"overview-list"}>
                            {eventData &&
                                eventData.OverviewList &&
                                eventData.OverviewList.map((OverviewItem, index) => (
                                    <li key={index}>{OverviewItem.ListName || ""}</li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={"benefit-container"}>
                <div className={"container"}>
                    <div className={"benefit-section"}>
                        <h3 className={"benefit-section-title"}>
                            {(eventData &&
                                eventData.EventDetailedSection &&
                                eventData.EventDetailedSection[2] &&
                                eventData.EventDetailedSection[2].Title) ||
                                ""}
                        </h3>
                        <ul className={"benefit-list"}>
                            {eventData &&
                                eventData.IndustryList &&
                                eventData.IndustryList.map((industryItem, index) => (
                                    <li key={index}>{industryItem.ListName || ""}</li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={"expect-container"}>
                <div className={"container"}>
                    <div className={"expect-section"}>
                        <h3 className={"expect-section-title"}>
                            {(eventData &&
                                eventData.EventDetailedSection &&
                                eventData.EventDetailedSection[3] &&
                                eventData.EventDetailedSection[3].Title) ||
                                ""}
                        </h3>
                        <div className={"description-container"}>
                            <p>
                                {(eventData &&
                                    eventData.EventDetailedSection &&
                                    eventData.EventDetailedSection[3] &&
                                    eventData.EventDetailedSection[3].Description) ||
                                    ""}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"webinarisFor-Container"}>
                <div className={"container"}>
                    <div className={"webinarFor-section"}>
                        <h3 className={"webinarFor-section-title"}>
                            {(eventData &&
                                eventData.EventDetailedSection &&
                                eventData.EventDetailedSection[4] &&
                                eventData.EventDetailedSection[4].Title) ||
                                ""}
                        </h3>
                        <ul className={"list-name-container"}>
                            {eventData &&
                                eventData.WebinarIsForList &&
                                eventData.WebinarIsForList.map((webinarItem, index) => (
                                    <li key={index}>{webinarItem.ListName || ""}</li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={"feature-container"}>
                <div className={"container"}>
                    <div className={"feature-section"}>
                        <h3 className={"feature-section-title"}>
                            {(eventData &&
                                eventData.EventDetailedSection &&
                                eventData.EventDetailedSection[5] &&
                                eventData.EventDetailedSection[5].Title) ||
                                ""}
                        </h3>
                        <div className={"feature-list-container"}>
                            {eventFeatures.map(feature => (
                                <div key={feature.id} className={"feature-item"}>
                                    <h4>{feature.attributes.Title}</h4>
                                    <ul>
                                        {feature.attributes.EventFeatureList.map(item => (
                                            <li key={item.id}>{item.ListName || ""}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                        <div className={"description-container"}>
                            <p>
                                {(eventData &&
                                    eventData.EventDetailedSection &&
                                    eventData.EventDetailedSection[5] &&
                                    eventData.EventDetailedSection[5].Description) ||
                                    ""}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"host-container"}>
                <div className={"container"}>
                    <div className={"host-section"}>
                        <h3 className={"host-title"}>
                            {(eventData &&
                                eventData.EventDetailedSection &&
                                eventData.EventDetailedSection[6] &&
                                eventData.EventDetailedSection[6].Title) ||
                                ""}
                        </h3>
                        <div className={"host-card-event"}>
                            {eventHosts.map((host: EventHost) => (
                                <div key={host.id} className={"host-info"}>
                                    <div className={"header-host"}>
                                        <div className={"left-header-host"}>
                                            <h4>{host.attributes.Name}</h4>
                                            <p>{host.attributes.Designation}</p>
                                        </div>
                                        <img
                                            src={
                                                host.attributes.Profile &&
                                                    host.attributes.Profile.data &&
                                                    host.attributes.Profile.data[0] &&
                                                    host.attributes.Profile.data[0].attributes &&
                                                    host.attributes.Profile.data[0].attributes.url
                                                    ? host.attributes.Profile.data[0].attributes.url
                                                    : ""
                                            }
                                            alt={host.attributes.Name || ""}
                                        />
                                    </div>
                                    <p className={"host-description"}>{host.attributes.About || ""}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={"faq-container"}>
                <div className={"container"}>
                    <div className={"faq-section"}>
                        <div className={"left-section-faq"}>
                            <h3>
                                {(eventData &&
                                    eventData.EventDetailedSection &&
                                    eventData.EventDetailedSection[7] &&
                                    eventData.EventDetailedSection[7].Title) ||
                                    ""}
                            </h3>
                        </div>
                        <div className={"right-section-faq"}>
                            {eventData &&
                                eventData.FaqQuestions &&
                                eventData.FaqQuestions.map((faq, index: number) => (
                                    <div className={"faq-item"} key={index}>
                                        <div
                                            className={`faq-question ${activeIndex === index ? "active" : ""}`}
                                            onClick={() => toggleAccordion(index)}
                                        >
                                            <span>{faq.Question || ""}</span>
                                            {activeIndex === index ? <HiMinus /> : <HiPlus />}
                                        </div>
                                        <div
                                            className={"faq-answer"}
                                            style={{
                                                maxHeight: activeIndex === index ? "200px" : "0",
                                                opacity: activeIndex === index ? 1 : 0,
                                                transition: "max-height 0.3s ease, opacity 0.3s ease",
                                                overflow: "hidden",
                                            }}
                                        >
                                            {faq.Answer || "Answer not available"}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={"join-webinar-container"}>
                <div className={"container"}>
                    <div className={"joinwebinar-section"}>
                        <h3 className={"joinwebinar-title"}>
                            {(eventData &&
                                eventData.EventDetailedSection &&
                                eventData.EventDetailedSection[8] &&
                                eventData.EventDetailedSection[8].Title) ||
                                ""}
                        </h3>
                        <div className={"description-container"}>
                            <p>
                                {(eventData &&
                                    eventData.EventDetailedSection &&
                                    eventData.EventDetailedSection[8] &&
                                    eventData.EventDetailedSection[8].Description) ||
                                    ""}
                            </p>
                        </div>
                        <button className={"register-button"}>
                            {(eventData && eventData.JoinRegisterButton && eventData.JoinRegisterButton.Title) || ""}
                            <span>
                                <img src={upIcons || ""} alt={"Icon"} height={15} width={15} />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default EventDetailedPage;
